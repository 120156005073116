import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Row, TextInput } from 'react-materialize';
import { connect } from 'react-redux';
import AddressAutocomplete from './AddressAutocomplete';
import {
  allUsStates,
  stateAbbreviationToName,
  stateNameToAbbreviation,
} from './UsStates';
import { apiGet } from '../../brainApi';
import ErrorModal from './ErrorModal';
import Select from './Select';
import {
  mediaQueryMaxWidth600,
  Z3P_CONFIG,
  tracRudderStackkEvent,
  currentPageInfo,
  getChannel,
} from '../../helper';

const SetAddressForm = ({
  onSetAddress,
  propAddress,
  hideServiceabilitySection = false,
  isLoading,
  setIsLoading,
  // Stripped version shows only the search bar and fields to edit
  strippedVersion = false,
  onChangeField = null,
  onSelectAddress = () => {},
  onCancel = () => {},
  onCheckAddressValidity = () => {},
  dispatch,
  serviceAreas,
  homeAddress,
  z3pConfiguration,
}) => {
  const originalAddressFields = {
    display_name: (propAddress && propAddress.display_name) || null,
    address_line_1: (propAddress && propAddress.address_line_1) || null,
    address_line_2: (propAddress && propAddress.address_line_2) || null,
    city: (propAddress && propAddress.city) || null,
    state:
      (propAddress &&
        propAddress.state &&
        (stateAbbreviationToName(propAddress.state) || propAddress.state)) ||
      null,
    postal_code: (propAddress && propAddress.postal_code) || null,
    country: (propAddress && propAddress.country) || null,
  };
  const originalServiceabilityNote =
    (propAddress && propAddress.serviceability_note) || '';
  const [addressFields, setAddressFields] = useState(originalAddressFields);
  const [serviceabilityNote, setServiceabilityNote] = useState(
    originalServiceabilityNote,
  );

  const checkboxQuestions = Z3P_CONFIG.address_verification_questions;
  const [checkboxValues, setCheckboxValues] = useState(
    checkboxQuestions.map(() => false),
  );
  const [requiredFieldsValid, setRequiredFieldsValid] = useState(true);
  const [addressZipValid, setAddressZipValid] = useState(true);
  const [addressZipPossible, setAddressZipPossible] = useState(true);
  const [enteringAddressManually, setEnteringAddressManually] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState('');

  const addressIsChosen =
    !!addressFields.address_line_1 ||
    !!addressFields.address_line_2 ||
    !!addressFields.city ||
    !!addressFields.state ||
    !!addressFields.postal_code ||
    !!addressFields.country ||
    !!serviceabilityNote;

  const z3pShowAddressVerification =
    Z3P_CONFIG.is_address_verification_question_enabled;
  const showAddressVerification =
    z3pShowAddressVerification && !hideServiceabilitySection;

  const checkRequiredFieldsValidity = (addr) => {
    const validRequiredFields =
      !!addr.address_line_1 &&
      !!addr.city &&
      !!addr.state &&
      !!addr.postal_code;
    setRequiredFieldsValid(validRequiredFields);
    return validRequiredFields;
  };

  const checkAddressZipValidity = (addr) => {
    const validZip =
      addr.postal_code && serviceAreas.includes(addr.postal_code);
    setAddressZipValid(validZip);
    return validZip;
  };

  const checkPossibleZip = (addr) => {
    const validZip =
      addr.postal_code &&
      addr.postal_code.length === 5 &&
      !isNaN(addr.postal_code);
    setAddressZipPossible(validZip);
    return validZip;
  };

  const checkAddressValidity = (addr) => {
    const validity =
      checkRequiredFieldsValidity(addr) &&
      checkPossibleZip(addr) &&
      checkAddressZipValidity(addr);
    onCheckAddressValidity(validity);
    return validity;
  };

  useEffect(() => {
    apiGet('/service-area')
      .then((response) => response.json())
      .then((data) => {
        const zips = data.map((zip) => {
          return zip.postal_code;
        });
        dispatch({ type: 'SET_SERVICE_AREAS', serviceAreas: zips });
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('enteringAddressManually', enteringAddressManually);
    if (serviceAreas && serviceAreas.length !== 0) {
      checkAddressValidity(addressFields);
    }
  }, [addressFields, serviceAreas]);

  // add more info into the address' serviceability note
  const formatServiceabilityNote = (customerNote, checkboxVals) => {
    const formattedServiceabilityNote = `Customer Notes: "${customerNote}"${checkboxQuestions.map(
      (q, i) => `\n${checkboxVals[i] ? '' : 'UN'}CONFIRMED: ${q}`,
    )}`;
    return formattedServiceabilityNote;
  };

  const handleSelectAddress = (selectedAddress) => {
    const addr = {
      address_line_1: `${selectedAddress.streetNumber ||
        ''} ${selectedAddress.street || ''}`,
      city: selectedAddress.city,
      state: selectedAddress.state,
      postal_code: selectedAddress.postalCode,
      country: selectedAddress.country,
    };
    localStorage.setItem('enteringAddressManually', enteringAddressManually);
    setAddressFields({ ...addressFields, ...addr });
    onSelectAddress({
      ...addr,
      serviceability_note: formatServiceabilityNote('', checkboxValues),
    });
  };

  const handleChangeField = (event) => {
    const fieldObject = { [event.currentTarget.id]: event.currentTarget.value };
    setAddressFields({
      ...addressFields,
      ...fieldObject,
    });
    if (onChangeField) onChangeField(fieldObject);
  };

  const handleClickSave = () => {
    setIsLoading(true);
    if (checkAddressValidity(addressFields)) {
      const formattedServiceabilityNote = !showAddressVerification
        ? serviceabilityNote
        : formatServiceabilityNote(serviceabilityNote, checkboxValues);
      onSetAddress({
        ...addressFields,
        state:
          stateNameToAbbreviation(addressFields.state) ||
          addressFields.state.toUpperCase(),
        serviceability_note: formattedServiceabilityNote,
      });
    }
  };

  const handleClickCancel = () => {
    setAddressFields(originalAddressFields);
    setServiceabilityNote(originalServiceabilityNote);
    onCancel();
  };

  const ErrorMessage = () => {
    let error;
    const channel = getChannel();
    if (!requiredFieldsValid) error = 'Please fill out all required fields (*)';
    else if (!addressZipPossible)
      error = `'${addressFields.postal_code}' is not a valid US Postal Code. Please enter a 5-digit numeric postal code.`;
    else if (!addressZipValid)
      error = `Sorry, we do not yet do at-home services in ${addressFields.postal_code}.`;
    else return null;

    if (
      addressFields.address_line_1 === '' ||
      addressFields.city === '' ||
      addressFields.postal_code === '' ||
      !addressZipPossible ||
      !addressZipValid
    ) {
      tracRudderStackkEvent('Address_Form_Error', {
        proname: z3pConfiguration.z3p_client_name,
        path: currentPageInfo.PATH,
        url: currentPageInfo.URL,
        title: currentPageInfo.TITLE
          ? currentPageInfo.TITLE
          : Z3P_CONFIG.customer_facing_name,
        referrer: document.referrer,
        channel,
        addressSelect: addressFields.address_line_1,
        city: addressFields.city,
        state: addressFields.state,
        postal_code: addressFields.postal_code,
        country: addressFields.country,
        error,
        method: enteringAddressManually ? 'Manual' : 'Automated',
      });
    }
    return <p className="zippity-red">{error}</p>;
  };

  const CheckboxQuestions = () => {
    if (!showAddressVerification) return null;
    return checkboxQuestions.map((q, i) => {
      return (
        <Col s={12}>
          <Checkbox
            name={`q${i}`}
            label={q}
            checked={checkboxValues[i]}
            onChange={() => {
              const newCheckboxValues = checkboxValues.map((v, j) =>
                i === j ? !v : v,
              );
              if (onChangeField)
                onChangeField({
                  serviceability_note: formatServiceabilityNote(
                    serviceabilityNote,
                    newCheckboxValues,
                  ),
                });
              setCheckboxValues(newCheckboxValues);
            }}
          />
        </Col>
      );
    });
  };

  const CancelButton = () => (
    <Button className="btn-gray" onClick={() => handleClickCancel()}>
      Cancel
    </Button>
  );

  const SaveButton = () => (
    <Button
      className="btn-teal"
      onClick={() => handleClickSave()}
      disabled={!requiredFieldsValid || !addressZipValid || isLoading}
    >
      {isLoading ? 'Saving...' : 'Save'}
    </Button>
  );

  return (
    <div className="edit_address_form">
      {!addressIsChosen && !enteringAddressManually ? (
        <Row>
          <Col className="s12">
            {strippedVersion ? null : (
              <h5 className="subtext">Enter your home address</h5>
            )}
            <AddressAutocomplete
              setAddressError={setAddressError}
              setAddressErrorMessage={setAddressErrorMessage}
              onSelectAddress={handleSelectAddress}
              onEnterManually={() => setEnteringAddressManually(true)}
              selectedHomeAddress={homeAddress ?? ''}
            />
          </Col>
          {strippedVersion ? null : (
            <Col>
              <CancelButton />
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <Col m={12} s={12}>
            <TextInput
              label="Display Name (optional)"
              id="display_name"
              value={addressFields.display_name}
              onChange={handleChangeField}
              validate
              m={12}
              s={12}
            />
          </Col>
          <Col m={12} s={12}>
            <TextInput
              label="Address Line 1 *"
              id="address_line_1"
              value={addressFields.address_line_1}
              onChange={handleChangeField}
              validate
              required="required"
              m={12}
              s={12}
            />
          </Col>
          <Col m={12} s={12}>
            <TextInput
              label="Address Line 2"
              id="address_line_2"
              value={addressFields.address_line_2}
              onChange={handleChangeField}
              validate
              m={12}
              s={12}
            />
          </Col>
          <Col l={4} m={12} s={12}>
            <TextInput
              label="City *"
              id="city"
              value={addressFields.city}
              onChange={handleChangeField}
              validate
              required="required"
              m={12}
              s={12}
            />
          </Col>
          <Col l={4} m={12} s={12}>
            <Select
              label="State/Province/Region *"
              id="state"
              value={addressFields.state}
              onChange={handleChangeField}
              validate
              m={12}
              s={12}
            >
              <option key="disabled" value="">
                Select your state
              </option>
              {allUsStates.map((s) => (
                <option key={s.name} value={s.name}>
                  {s.name}
                </option>
              ))}
              {!addressFields.state ||
              allUsStates.map((s) => s.name).includes(addressFields.state) ? (
                []
              ) : (
                <option
                  key={addressFields.state}
                  value={addressFields.state}
                  disabled
                >
                  {addressFields.state}
                </option>
              )}
            </Select>
          </Col>
          <Col l={4} m={12} s={12}>
            <TextInput
              label="ZIP/Postal Code *"
              id="postal_code"
              value={addressFields.postal_code}
              onChange={handleChangeField}
              validate
              required="required"
              m={12}
              s={12}
            />
          </Col>
          <Col m={12} s={12}>
            <TextInput
              label="Country"
              id="country"
              value={addressFields.country}
              onChange={handleChangeField}
              validate
              m={12}
              s={12}
            />
          </Col>
          {!showAddressVerification ? null : (
            <Col>
              <Row>
                <Col>
                  <p>
                    Tell us about your property. This will help us determine how
                    our vehicles can best meet your needs.
                  </p>
                  <CheckboxQuestions />
                </Col>
              </Row>
              <Row style={{ paddingRight: '1rem' }}>
                <TextInput
                  style={
                    mediaQueryMaxWidth600().matches
                      ? { marginTop: '1.5rem' }
                      : {}
                  }
                  label="Is there anything else you'd like us to know about this location?"
                  value={serviceabilityNote}
                  onChange={(e) => {
                    if (onChangeField)
                      onChangeField({
                        serviceability_note: formatServiceabilityNote(
                          e.target.value,
                          checkboxValues,
                        ),
                      });
                    setServiceabilityNote(e.target.value);
                  }}
                  validate
                  m={12}
                  s={12}
                />
              </Row>
            </Col>
          )}
          <div className="col s12 m12">
            <ErrorMessage />
          </div>
          <br />
          {strippedVersion ? null : (
            <React.Fragment>
              <Col>
                <SaveButton />
              </Col>
              <Col>
                <CancelButton />
              </Col>
            </React.Fragment>
          )}
        </Row>
      )}
      <ErrorModal
        error={addressError}
        onClose={() => setAddressError(false)}
        errorText={addressErrorMessage}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    serviceAreas: state.ui.serviceAreas,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(SetAddressForm);

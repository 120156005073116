/* globals alert */

import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import Zippity from './components/Zippity';
import { apiPost, hasAccessToken } from '../brainApi';
import { setPageTitle, tracRudderStackkEvent, currentPageInfo } from '../helper';
import { connect } from "react-redux";

class ForgotPassword extends Component {
  state = { processing: false, response: '' };

  componentWillMount() {
    setPageTitle('Forgot Password');
  }

  componentDidMount() {
    if (hasAccessToken()) {
      browserHistory.push('/account');
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const email = this.email.value;

    apiPost('/send-reset-link', { email })
      .then((r) => r.json())
      .then((r) => {
        if (r.Status === 'Success') {
          this.setState({ response: r.Message });
        } else {
          alert(r.Message);
        }
      });
  };

  navigateToLogin = () => {
    const { z3pConfiguration } = this.props;
    tracRudderStackkEvent('Zippity_Log_in', {
      proname: z3pConfiguration.z3p_client_name,
      description: `User clicked the login button on ${document.title} page`,
      button_name: 'Back to login',
      account_type: '',
      account_id: '',
      page_information: {
        path: currentPageInfo['PATH'],
        url: currentPageInfo['URL'],
        title: document.title
      }
    });

    browserHistory.push('/login');
  };

  render() {
    const { processing, response } = this.state;
    let submitClass = 'right btn-large header btn';
    if (processing) {
      submitClass += 'btn disabled ';
    }

    let body = '';
    if (response) {
      body = <p>{response}</p>;
    } else {
      body = (
        <form className="forgot_password_form" onSubmit={this.handleSubmit}>
          <p>
            Enter the email address associated with your account, and we’ll
            email you a link to reset your password.
          </p>

          <div className="input-field col m12">
            <input
              ref={(i) => (this.email = i)}
              id="email"
              name="email"
              type="email"
              className="validate"
              required="required"
            />
            <label htmlFor="email">Email</label>
          </div>
          <input
            disabled={processing}
            className={submitClass}
            type="submit"
            name="action"
            value="Send Reset Link"
          />
        </form>
      );
    }

    return (
      <Zippity active="login">
        <div id="login">
          <div className="row">
            <div className="col s12 center">
              <h2>Reset Password</h2>
            </div>
          </div>
          <div className="row login-form">
            <div className="col s12 center" />
            <div
              className="col s12 input-wrapper neue"
              style={{ paddingTop: 20 }}
            >
              {body}

              <div className="clearfix" />

              <div className="center">
                <a
                  onClick={this.navigateToLogin}
                  className="waves-effect waves-light right create-account "
                >
                  <i className="material-icons tiny">arrow_back_ios_new</i> Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(ForgotPassword);

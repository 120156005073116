/* globals window fetch */

import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-materialize';

import { browserHistory } from 'react-router';
import Pricing from './components/Pricing';
import { setPageTitle, datetimeToTime, getCookie } from '../helper';
import { hasAccessToken } from '../brainApi';
import PrettyDate from './components/PrettyDate';
import ReviewYourCar from './Review/ReviewYourCar';
import ReviewServices from './Review/ReviewServices';
import ReviewPrices from './Review/ReviewPrices';

class Finished extends Component {
  constructor(props) {
    super();
  }

  componentWillMount() {
    setPageTitle('Thank You!');
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch({
      type: 'SET_REFERRER',
      referralCode: '',
      refSource: '',
    });
  }

  render() {
    const {
      dispatch,
      z3pConfiguration,
      account,
      homeAddress,
      keyosk,
      parking,
      schedule,
      customerLocation,
      clientLocations,
      vehicles,
    } = this.props;
    const {
      customer_facing_name: serviceProviderName,
      customer_facing_phone: serviceProviderPhone,
      has_home_button_after_booking: needsHomeButton,
      scheduling_system: schedulingSystem,
      service_locations: serviceLocations,
      pickup_delivery_service_location: pickupDeliveryServiceLocation,
      client_url: clientUrl,
      is_skipping_vehicle: isSkippingVehicle,
    } = z3pConfiguration;
    const tipAmountDollars = 0;
    const redirectToBookAgain = () => {
      const userIsLoggedIn = hasAccessToken();
      if (userIsLoggedIn) {
        browserHistory.push('/account');
      } else if (isSkippingVehicle) {
        browserHistory.push('/pricing/simple');
      } else {
        browserHistory.push('/pricing/vehicle-lookup');
      }

      dispatch({ type: 'SET_CART', cart: [] });
    };
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    };
    let conversionPartnerId =
      process.env.REACT_APP_CONVERSION_SANDBOX_PARTNER_ID !== undefined
        ? process.env.REACT_APP_CONVERSION_SANDBOX_PARTNER_ID
        : '20003021';
    let conversionEndpoint =
      process.env.REACT_APP_CONVERSION_SANDBOX_ENDPOINT !== undefined
        ? process.env.REACT_APP_CONVERSION_SANDBOX_ENDPOINT
        : 'https://www.google.com/maps/conversion/debug/collect';
    if (process.env.REACT_APP_ENVIRO === 'production') {
      if (
        process.env.REACT_APP_CONVERSION_PROD_PARTNER_ID &&
        process.env.REACT_APP_CONVERSION_PROD_ENDPOINT
      ) {
        conversionPartnerId = process.env.REACT_APP_CONVERSION_PROD_PARTNER_ID;
        conversionEndpoint = process.env.REACT_APP_CONVERSION_PROD_ENDPOINT;
      }
    }
    const rwgTokenCookie = getCookie('_rwg_token');
    if (
      rwgTokenCookie !== '' &&
      conversionEndpoint !== '' &&
      conversionPartnerId != ''
    ) {
      const rwgTokenVal = rwgTokenCookie;
      fetch(conversionEndpoint, {
        headers,
        mode: 'no-cors',
        method: 'POST',
        body: JSON.stringify({
          conversion_partner_id: conversionPartnerId,
          rwg_token: rwgTokenVal,
          merchant_changed: 2,
        }),
      }).then((response) => {
        console.log(response);
      });
    }
    const isAtHomeService = !!homeAddress.addressIDSelected;
    const hasShopService = serviceLocations.includes('shop');
    const sectionDivider = <div className="section_divider" />;
    let contact = null;
    let location = null;
    let scheduleDisplay = null;
    let vehicleSection = null;
    if (account) {
      contact = (
        <div className="customer-info">
          <div className="header">
            <h5>Customer Info</h5>
          </div>
          <ul>
            <li>
              {account.first_name} {account.last_name}
            </li>
            <li>{account.email}</li>
            <li>{account.phone}</li>
          </ul>
        </div>
      );
    }
    if (isAtHomeService) {
      const { address } = homeAddress;
      location = (
        <div className="d-flex">
          <h6>Service Location</h6>
          <ul>
            {schedule.isPickupAndDelivery && (
              <li style={{ fontSize: '15px' }}>
                Vehicle will be picked up from:
              </li>
            )}
            <li>
              <strong>{address.display_name}</strong>
            </li>
            <li>{address.address_line_1}</li>
            <li>{address.address_line_2}</li>
            <li>
              {address.city}, {address.state} {address.postal_code}
            </li>
          </ul>
          {schedule.isPickupAndDelivery && (
            <p>{pickupDeliveryServiceLocation}</p>
          )}
        </div>
      );
    } else if (keyosk && parking) {
      location = (
        <div className="d-flex">
          <h6>Location</h6>
          <ul>
            <li>Keyosk: {keyosk.name}</li>
            <li>Parking Lot: {parking.name}</li>
          </ul>
        </div>
      );
    } else if (hasShopService) {
      const shopAddress = clientLocations.find(
        (cl) => cl.client_location_id === customerLocation.clientLocationId,
      );
      location = (
        <div className="d-flex">
          <h6>Location</h6>
          <ul>
            <li>{customerLocation.location}</li>
            {shopAddress && shopAddress.address && (
              <li>{shopAddress.address.one_line_human_readable_address}</li>
            )}
          </ul>
        </div>
      );
    }
    if (schedule) {
      scheduleDisplay = (
        <div className="d-flex">
          <h6>Date & Time</h6>
          <ul>
            <li>
              {schedule.date ? (
                <PrettyDate dateString={schedule.date} />
              ) : (
                'Date not yet set - we will reach out!'
              )}
            </li>
            <li>
              {schedulingSystem === 'time_slots'
                ? `${datetimeToTime(schedule.confirmedAppointmentStartTime)}`
                : `${schedule.plannedArrivalTime} - ${schedule.plannedDepartureTime}`}
            </li>
          </ul>
        </div>
      );
    }
    if (vehicles) {
      vehicleSection = (
        <div className="d-flex">
          <h6>Vehicle</h6>
          <ul>
            <li>
              <ReviewYourCar />
            </li>
          </ul>
        </div>
      );
    }
    const appointmentSection = (
      <div className="appointment-info">
        <div className="header">
          <h5>Appointment Details</h5>
        </div>
        {location}
        {scheduleDisplay}
        {vehicleSection}
      </div>
    );
    const serviceSection = (
      <div className="service-info">
        <div className="header">
          <h5>Services</h5>
        </div>
        <ReviewServices />
      </div>
    );

    return (
      <Pricing
        currentStep="Schedule"
        h1={`Thank you for scheduling your service with ${serviceProviderName}!`}
        h2="Here's what happens next"
      >
        <div className="row neue finished_box">
          <Col l={12}>
            <p>
              <span className="number_style">1</span>
              You’ll receive an email shortly confirming your service.
            </p>

            <p>
              <span className="number_style">2</span> We'll remind you of your
              service the day before via email and text message.
            </p>

            <p>
              <span className="number_style">3</span> Contact us at{' '}
              {serviceProviderPhone} with any questions.
            </p>
            {needsHomeButton && (
              <a href={clientUrl} target="_blank" rel="noreferrer">
                <Button large style={{ marginRight: '1em' }}>
                  {' '}
                  Home{' '}
                </Button>
              </a>
            )}

            <Button
              large
              style={{
                marginTop: '26px',
                display: 'flex',
                alignItems: 'center',
              }}
              className="btn btn-darkgray"
              onClick={redirectToBookAgain}
            >
              Book another service{' '}
              <i className="material-icons" style={{ marginLeft: '5px' }}>
                arrow_forward_ios
              </i>
            </Button>
          </Col>
          <Col l={12}>
            <h3>Appointment Summary</h3>
            <div className="review" style={{ marginTop: '24px' }}>
              <Row>
                <Col m={12} className="review-column-left">
                  {contact}
                  {sectionDivider}
                  {appointmentSection}
                  {sectionDivider}
                  {serviceSection}
                  <ReviewPrices
                    tipAmountDollars={tipAmountDollars}
                    sectionDivider={sectionDivider}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </div>
      </Pricing>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    customerLocation: state.ui.customerLocation,
    z3pConfiguration: state.ui.z3pConfiguration,
    homeAddress: state.ui.homeAddress,
    schedule: state.ui.schedule,
    vehicles: state.ui.vehicles,
    clientLocations: state.ui.clientLocations,
  };
}

export default connect(mapStateToProps, null)(Finished);

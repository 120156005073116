/* globals alert fetch localStorage window */
import Raven from 'raven-js';
import { Z3P_CONFIG } from './helper';

// Some browsers don't always allow localStorage.
// Examples:
//   - IE 10
//   - Safari, if you choose Preferences -> Privacy -> Block all cookies
// For those users, use in-memory storage in JS instead.
// https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
const inMemoryStorage = {};

function getAccessToken() {
  try {
    return localStorage.getItem('brain_token');
  } catch (error) {
    if (inMemoryStorage.hasOwnProperty('brain_token')) {
      return inMemoryStorage.brain_token;
    }
    return null;
  }
}

export const hasAccessToken = () => {
  const token = getAccessToken();
  return token && token !== '';
};

export const storeAccessToken = (token) => {
  try {
    if (token) {
      localStorage.setItem('brain_token', token);
    }
  } catch (error) {
    inMemoryStorage.brain_token = token;
  }
};

export const clearAccessToken = () => {
  try {
    localStorage.removeItem('brain_token');
  } catch (error) {
    delete inMemoryStorage.brain_token;
  }
};

function encodeParams(params) {
  const ret = [];

  Object.keys(params).forEach((k) => {
    const key = encodeURIComponent(k);
    const val = encodeURIComponent(params[k]);
    ret.push(`${key}=${val}`);
  });

  return ret.join('&');
}

const stagingSTMultitenantDomain = '.staging.simpleservice.com';
const prodSTMultitenantDomain = '.simpleservice.com';

const stagingUSMultitenantDomain = '.staging-book.zippityinfra.com';
const prodUSMultitenantDomain = '.book.zippityinfra.com';
const prodUSCustomDomain = '.zippitycustom.com';

const allMultitenantDomains = [
  stagingSTMultitenantDomain,
  prodSTMultitenantDomain,
  stagingUSMultitenantDomain,
  prodUSMultitenantDomain,
  prodUSCustomDomain,
];

const stageMultitenantDomains = [
  stagingSTMultitenantDomain,
  stagingUSMultitenantDomain,
];

const prodMultitenantDomains = [
  prodSTMultitenantDomain,
  prodUSMultitenantDomain,
  prodUSCustomDomain,
];

const customDomainToProNameProdUS = {
  'first-starter-pro.zippitycars.com': 'first-starter-pro',
  'second-starter-pro.zippitycars.com': 'second-starter-pro',
};

const getSubdomainPrefix = () => {
  const { hostname } = window.location;

  // Custom domains
  if (customDomainToProNameProdUS[hostname]) {
    return customDomainToProNameProdUS[hostname];
  }

  let retVal = null;
  if (hostname.includes('.staging')) {
    stageMultitenantDomains.forEach((domain) => {
      if (hostname.endsWith(domain)) {
        const len = hostname.length - domain.length;
        const prefix = hostname.substring(0, len);
        console.log('HOSTNAME', hostname);
        console.log('PREFIX', prefix);
        retVal = prefix;
      }
    });
  } else {
    prodMultitenantDomains.forEach((domain) => {
      if (hostname.endsWith(domain)) {
        const len = hostname.length - domain.length;
        const prefix = hostname.substring(0, len);
        console.log('HOSTNAME', hostname);
        console.log('PREFIX', prefix);
        retVal = prefix;
      }
    });
  }
  return retVal;
};

// for browser
export const redirectUrl = () => {
  const hostname = window.location.href;
  if (hostname.includes(stagingUSMultitenantDomain)) {
    window.location.href = hostname.replace(
      stagingUSMultitenantDomain,
      stagingSTMultitenantDomain,
    );
  }
  if (hostname.includes(prodUSMultitenantDomain)) {
    window.location.href = hostname.replace(
      prodUSMultitenantDomain,
      prodSTMultitenantDomain,
    );
  }
};

const getBrain2Url = () => {
  const subdomainPrefix = getSubdomainPrefix();
  if (subdomainPrefix) {
    const { hostname } = window.location;
    if (
      hostname.endsWith(stagingUSMultitenantDomain) ||
      hostname.endsWith(stagingSTMultitenantDomain)
    ) {
      return 'https://stbrain2-staging.zippityinfra.com';
    }

    if (
      hostname.endsWith(prodUSMultitenantDomain) ||
      hostname.endsWith(prodSTMultitenantDomain) ||
      hostname.endsWith(prodUSCustomDomain) ||
      customDomainToProNameProdUS[hostname]
    ) {
      return 'https://stbrain2-prod.zippityinfra.com';
    }
  }
  return process.env.REACT_APP_BRAIN_2_API_URL;
};

export const getProNameHeader = () => {
  return getSubdomainPrefix() || process.env.REACT_APP_Z3P;
};

export const apiGet = (path, params = null) => {
  let fullPath = `${getBrain2Url()}${path}`;
  if (params) {
    const queryString = encodeParams(params);
    fullPath = `${fullPath}?${queryString}`;
  }

  const headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Pro-Name': getProNameHeader(),
  };

  const token = getAccessToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return fetch(fullPath, { headers }).then((response) => {
    if (response.status === 403) {
      Raven.captureMessage(`Session expired from GET ${path}`);
      alert('Sorry, your session is expired. Please log in.');
      clearAccessToken();
      window.location = '/login';
    }
    if (response.headers.get('token')) {
      storeAccessToken(response.headers.get('token'));
    }
    return response;
  });
};

export const apiPost = (path, payload) => {
  const fullPath = `${getBrain2Url()}${path}`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pro-Name': getProNameHeader(),
  };

  const token = getAccessToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const body = JSON.stringify(payload);

  return fetch(fullPath, { method: 'POST', headers, body }).then((response) => {
    if (response.status === 403) {
      Raven.captureMessage(`Session expired from POST ${path}`);
      alert('Sorry, your session is expired. Please log in again.');
      clearAccessToken();
      window.location = '/login';
    }
    if (response.headers.get('token')) {
      storeAccessToken(response.headers.get('token'));
    }
    return response;
  });
};

export const apiPut = (path, payload) => {
  const fullPath = `${getBrain2Url()}${path}`;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pro-Name': getProNameHeader(),
  };

  const token = getAccessToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const body = JSON.stringify(payload);

  return fetch(fullPath, { method: 'PUT', headers, body }).then((response) => {
    if (response.status === 403) {
      Raven.captureMessage(`Session expired from POST ${path}`);
      alert('Sorry, your session is expired. Please log in again.');
      clearAccessToken();
      window.location = '/login';
    }
    if (response.headers.get('token')) {
      storeAccessToken(response.headers.get('token'));
    }
    return response;
  });
};

const translateResponse = (data) => {
  const dates = Object.keys(data).sort();
  const compatibleDates = [];
  dates.forEach((date) => {
    if (Z3P_CONFIG.scheduling_system === 'time_slots') {
      data[date].forEach((availability) => {
        compatibleDates.push({ ...availability, date });
      });
    } else {
      const optimalAvailability = data[date]
        .sort()
        .filter((a) => a.is_optimal)
        .pop();
      if (optimalAvailability)
        compatibleDates.push({ ...optimalAvailability, date });
    }
  });
  return compatibleDates;
};

// Loads all dates for a client location that are compatible with a given list of service keys
// Includes available and not available dates
export const loadCompatibleDates = (
  clientLocationId,
  services = [],
  startDate = null,
  endDate = null,
) => {
  const serviceString = services
    .map((service) => service.service_offering_id)
    .join(',');
  const totalLaborHours = services.reduce((total, service) => {
    return total + service.labor_hours * (service.quantity || 1);
  }, 0);
  const url = '/get-compatible-dates';

  const params = {
    requested_service_offering_ids: serviceString,
    client_location_id: clientLocationId,
    total_labor_hours: totalLaborHours,
    use_new_version: 1,
  };

  if (startDate) {
    params.start_date = startDate;
  }

  if (endDate) {
    params.end_date = endDate;
  }

  return apiGet(url, params)
    .then((response) => {
      if (response.ok) {
        return new Promise((resolve, reject) => {
          try {
            response.json().then((data) => {
              resolve(translateResponse(data));
            });
          } catch (e) {
            reject(e);
          }
        });
      }
      throw new Error('JSON was not included in response!');
    })
    .catch((error) => {
      Raven.captureException(error);
      return [];
    });
};

export const loadCompatibleDatesForHomeAddress = (
  homeAddressId,
  services = [],
  startDate = null,
  endDate = null,
) => {
  const serviceString = services
    .map((service) => service.service_offering_id)
    .join(',');
  const totalLaborHours = services.reduce((total, service) => {
    return total + service.labor_hours * (service.quantity || 1);
  }, 0);
  const url = '/get-compatible-dates';

  // For recalls, we need to send the display name to check if it can be serviced at home
  // This strategy will work because we store all recalls under a single "Recall" service_offering
  let recall = {};
  const recallService = services.find(
    (service) =>
      service.short_name && service.short_name.toLowerCase().includes('recall'),
  );
  if (recallService && recallService.display_name) {
    const displayName = recallService.display_name.replace(
      /[^0-9a-zA-Z ]/g,
      '',
    );
    recall = { recall_service: displayName };
  }

  const params = {
    requested_service_offering_ids: serviceString,
    home_address_id: homeAddressId,
    total_labor_hours: totalLaborHours,
    ...recall,
    use_new_version: 1,
  };

  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }

  return apiGet(url, params)
    .then((response) => {
      if (response.ok) {
        return new Promise((resolve, reject) => {
          try {
            response.json().then((data) => {
              resolve(translateResponse(data));
            });
          } catch (e) {
            reject(e);
          }
        });
      }
      throw new Error('JSON was not included in response!');
    })
    .catch((error) => {
      Raven.captureException(error);
      return [];
    });
};

export const loadAtdProducts = (atdLocationId, tireSize) => {
  const url = `/atd/products/${atdLocationId}/${tireSize}`;
  return apiGet(url)
    .then((response) => response.json())
    .catch((error) => Raven.captureException(error));
};

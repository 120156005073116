/* globals alert */

import React, { Component } from 'react';
import md5 from 'md5';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import qstring from 'query-string';
import { Button } from 'react-materialize';
import Zippity from './components/Zippity';
import { apiPut, hasAccessToken, storeAccessToken } from '../brainApi';
import { setPageTitle, tracRudderStackkEvent, currentPageInfo } from '../helper';

class ResetPassword extends Component {
  state = { processing: false, response: '', response_status: '' };

  componentWillMount() {
    setPageTitle('Reset Password');
  }

  // if they got here while already logged in send them to their account page
  componentDidMount() {
    if (hasAccessToken()) {
      browserHistory.push('/account');
    }
  }

  handleSubmit = (event) => {
    const { params } = this.props;
    event.preventDefault();

    const password = this.password.value;
    const passwordConfirm = this.passwordConfirm.value;

    if (password !== passwordConfirm) {
      alert("Error: your passwords don't match");
      return;
    }

    const payload = {
      rp_id: params.rp_id,
      md5: md5(password),
    };

    apiPut('/reset-password', payload)
      .then((res) => res.json())
      .then((json) => {
        this.setState({response_status: json.Status});
        if (json.Status === 'Success') {
          this.setState({ response: 'You have successfully changed password' });
          // If a user has an access token and a redirect was included, log in and redirect
          if (json.token) {
            const { location } = this.props;
            if (qstring && location && location.search) {
              const queryString = qstring.parse(location.search);
              if (queryString && queryString.redirect) {
                const path = decodeURIComponent(queryString.redirect);
                if (path.startsWith('/')) {
                  storeAccessToken(json.token);
                  browserHistory.push(path);
                }
              }
            }
          }
        } else {
          this.setState({ response: json.Message });
        }
      });
  };

  navigateToLogin = () => {
    const { z3pConfiguration } = this.props;
    tracRudderStackkEvent('Zippity_Log_in', {
      proname: z3pConfiguration.z3p_client_name,
      description: `User clicked the login button on ${document.title} page`,
      button_name: 'Back to login',
      account_type: '',
      account_id: '',
      page_information: {
        path: currentPageInfo['PATH'],
        url: currentPageInfo['URL'],
        title: document.title,
      },
    });

    browserHistory.push('/login');
  };

  render() {
    const { processing, response, response_status } = this.state;
    let submitClass = 'right btn-large header';
    if (processing) {
      submitClass += ' disabled ';
    }

    let body = '';
    if (response) {
      body = <div className={`reset_message ${response_status === 'Success' ? 'success' : 'error'}`}>{(response_status === 'Success') ? <i className="material-icons">task_alt</i> : <i className="material-icons">highlight_off</i> }<p dangerouslySetInnerHTML={{ __html: response }} /></div>;
    } else {
      body = (
        <form onSubmit={this.handleSubmit}>
          <div className="input-field col m12">
            <input
              ref={(i) => (this.password = i)}
              id="password"
              name="password"
              type="password"
              className="validate"
              required="required"
            />
            <label htmlFor="password">New Password</label>
          </div>
          <div className="input-field col m12">
            <input
              ref={(i) => (this.passwordConfirm = i)}
              id="password2"
              name="password2"
              type="password"
              className="validate"
              required="required"
            />
            <label htmlFor="password2">Confirm Password</label>
          </div>

          <Button
            disabled={processing}
            className={submitClass}
            type="submit"
            name="action"
          >
          Change Password
          </Button>
        </form>
      );
    }

    return (
      <Zippity active="login">
        <div id="login">
          <div className="row">
            <div className="col s12 center">
              <h2>Change Password</h2>
            </div>
          </div>
          <div className="row login-form">
            <div className="col s12 center" />
            <div
              className="col s12 input-wrapper neue"
              style={{ paddingTop: 20 }}
            >
              {body}

              <div className="clearfix" />

              <div className="center">
                <a
                  onClick={this.navigateToLogin}
                  className="waves-effect waves-light right create-account"
                >
                  <i className="material-icons tiny">arrow_back_ios_new</i> Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </Zippity>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(ResetPassword);

/* globals fbq window */
import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button, Checkbox } from 'react-materialize';
import Moment from 'moment-timezone';
import 'rc-slider/assets/index.css';

import Pricing from './components/Pricing';
import CompatibleDateSelector from './components/CompatibleDateSelector';
import CompatibleDateTimeSelector from './components/CompatibleDateTimeSelector';
import { addMessage } from '../zippity';
import { apiPut } from '../brainApi';
import {
  hoursToTimeString,
  setPageTitle,
  shouldUseFacebookPixel,
  calcLaborMinutes,
  roundLaborHours,
  timeStringToHours,
  KEY_LOCATION_OPTIONS,
  tracRudderStackkEvent
} from '../helper';
import { getActivePaymentMethods } from './Review/checkoutHelper';
import Select from './components/Select';
import TimeSlotCalendar from './components/TimeSlotCalendar';

const Slider = require('rc-slider');

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const Schedule = (props) => {
  const {
    schedule,
    diagnostic,
    homeAddress,
    z3pConfiguration,
    compatibleDates,
    cart,
    dispatch,
    account,
    changeBooking,
    customerLocation,
    clientLocations,
    keyLocation,
    goBack,
    vehicle,
  } = props;

  const newAvailableDates = compatibleDates
    .filter((d) => d.is_available)
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  const firstAvailableDate =
    newAvailableDates.length > 0 && newAvailableDates[0];

  const {
    time_range_min: defaultTimeRangeMin,
    time_range_max: defaultTimeRangeMax,
    minimum_onsite_hours: defaultMinimumOnsiteHours,
    z3p_client_name: z3pEnv,
    is_using_stripe: isUsingStripe,
    is_using_square: isUsingSquare,
    scheduling_system: schedulingSystem,
    service_locations,
    message_on_schedule_screen: messageOnScheduleScreen,
    is_using_time_blocks,
    is_skipping_vehicle,
  } = z3pConfiguration;

  const defaultPlannedArrivalTime =
    schedule.plannedArrivalTime ||
    (firstAvailableDate && firstAvailableDate.start_time) ||
    hoursToTimeString(defaultTimeRangeMin);
  const defaultPlannedDepartureTime =
    schedule.plannedDepartureTime ||
    (firstAvailableDate && firstAvailableDate.end_time) ||
    hoursToTimeString(defaultTimeRangeMax);

  const [timeRangeMin, setTimeRangeMin] = useState(
    (firstAvailableDate && timeStringToHours(firstAvailableDate.start_time)) ||
      defaultTimeRangeMin,
  );
  const [timeRangeMax, setTimeRangeMax] = useState(
    (firstAvailableDate && timeStringToHours(firstAvailableDate.end_time)) ||
      defaultTimeRangeMax,
  );
  const [plannedArrivalTime, setPlannedArrivalTime] = useState(
    defaultPlannedArrivalTime,
  );
  const [plannedDepartureTime, setPlannedDepartureTime] = useState(
    defaultPlannedDepartureTime,
  );
  const [
    confirmedAppointmentStartTime,
    setConfirmedAppointmentStartTime,
  ] = useState(null);
  const [customerNote, setCustomerNote] = useState(
    diagnostic.completeDescription ||
      schedule.customerNote ||
      schedule.note ||
      '',
  );
  const [date, setDate] = useState(schedule.date || '');
  const [serviceVehicleId, setServiceVehicleId] = useState(
    schedule.serviceVehicleId || '',
  );
  const [scheduleInstanceId, setScheduleInstanceId] = useState(
    schedule.scheduleInstanceId || '',
  );
  const [error, setError] = useState('');
  const [continueButtonClicked, setContinueButtonClicked] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [keyLocationOption, setKeyLocationOption] = useState(
    keyLocation ? keyLocation[0] : '',
  );
  const [keyLocationNote, setKeyLocationNote] = useState(
    keyLocation ? keyLocation[1] : '',
  );
  const [displayWaitlistForm, setDisplayWaitlistForm] = useState(false);
  const [waitlistNote, setWaitlistNote] = useState('');
  const [wantServiceSooner, setWantServiceSooner] = useState(
    schedule.isHandraise && !!schedule.date ? schedule.isHandraise : false,
  );
  const [waitlistNoteEdited, setWaitlistNoteEdited] = useState(false);
  const [isPickupAndDelivery, setIsPickupAndDelivery] = useState(null);
  const [calendarKey, setCalendarKey] = useState(1); // this is just used to rerender the Calendar component
  const [areCompatibleDatesLoading, setAreCompatibleDatesLoading] = useState(
    true,
  );
  const [laborHoursToDisplay, setLaborHoursToDisplay] = useState('');
  const [agreedToReceiveTexts, setAgreedToReceiveTexts] = useState(false);

  const isAtHomeService = !!homeAddress.addressIDSelected;
  const hasShopService = service_locations.includes('shop');

  useEffect(() => {
    setPageTitle('Schedule');
    // Scroll to top of page
    window.scrollTo(0, 0);
    // If the customer doesn't have anything in their cart,
    // direct them back to the Simple Services page.
    if (!cart || cart.length === 0) {
      browserHistory.push('/pricing/simple');
    }

    // If the logged-in customerId doesn't match the customerId on the scheduled work,
    // direct them back to My Account.
    // (This should only happen from the ProactiveSchedule component.)
    if (
      schedule.customerId &&
      account.customer_id &&
      schedule.customerId !== account.customer_id
    ) {
      const message =
        'Your account information does not match the appointment you are trying to access.';
      const timeoutSeconds = 15;
      addMessage(dispatch, message, timeoutSeconds, { messageType: 'error' });
      browserHistory.push('/account');
    }

    if (shouldUseFacebookPixel(z3pEnv)) {
      // Tell Facebook Pixel about that we are beginning the checkout process (meaning services have been selected)
      fbq('track', 'InitiateCheckout', {
        content_ids: cart.map((service) => service.service_offering_id),
        currency: 'USD',
      });
    }

    // Load active payment methods in preparation for the checkout screen
    getActivePaymentMethods(dispatch, isUsingStripe, isUsingSquare);
  }, []);

  const calculateLaborHours = () => {
    if (cart && cart.length > 0) {
      let laborHours = cart.reduce((cartHours, cartItem) => {
        cartHours += cartItem.labor_hours * (cartItem.quantity || 1);
        return cartHours;
      }, 0);

      if (schedule.date && compatibleDates.length > 0) {
        const matchingSchedule = compatibleDates.find(
          (d) => d.date === schedule.date && d.is_available,
        );
        if (matchingSchedule?.buffer_time_minutes) {
          laborHours += matchingSchedule.buffer_time_minutes / 60;
        }
      }

      if (laborHours < 1 && laborHours > 0) {
        return `${calcLaborMinutes(laborHours)} minutes`;
      }
      if (laborHours === 0) {
        return '15 minutes';
      }
      if (laborHours > 1) {
        return `${roundLaborHours(laborHours.toString())} hours`;
      }
      return '1 hour';
    }
    return '';
  };

  useEffect(() => {
    setLaborHoursToDisplay(calculateLaborHours());
  }, [cart, schedule, compatibleDates]);

  const setRange = (range) => {
    const [t0, t1] = range;
    // If time range is too small, expand it to the minimum
    if (t1 - t0 < defaultMinimumOnsiteHours) {
      const margin = defaultMinimumOnsiteHours - (t1 - t0);
      const distanceToMax = timeRangeMax - t1;
      const newRange =
        distanceToMax >= margin ? [t0, t1 + margin] : [t0 - margin, t1];
      setPlannedArrivalTime(hoursToTimeString(newRange[0]));
      setPlannedDepartureTime(hoursToTimeString(newRange[1]));
    } else {
      setPlannedArrivalTime(hoursToTimeString(t0));
      setPlannedDepartureTime(hoursToTimeString(t1));
    }
    setError('');
  };

  const showTooltip = () => {
    // Show the tooltips on the slider.
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 1000);
  };

  const selectScheduleInstance = (
    scheduleInstance,
    datesAvailable = null,
    reloadCalendar = false,
  ) => {
    setError('');
    setDate(scheduleInstance.date);
    setServiceVehicleId(scheduleInstance.service_vehicle_id);
    setScheduleInstanceId(scheduleInstance.schedule_instance_id);
    setIsPickupAndDelivery(scheduleInstance.is_pickup_and_delivery);
    setCalendarKey(reloadCalendar ? calendarKey + 1 : calendarKey);
    setTimeRangeMin(
      scheduleInstance.start_time &&
        timeStringToHours(scheduleInstance.start_time),
    );
    setTimeRangeMax(
      scheduleInstance.end_time && timeStringToHours(scheduleInstance.end_time),
    );

    if (scheduleInstance.date === null) {
      const note = datesAvailable
        ? `Add your availability here\n\nDates that do not work:\n${datesAvailable}`
        : '';
      setDisplayWaitlistForm(true);
      setWaitlistNote(note);
      setWantServiceSooner(false);
    } else {
      dispatch({ type: 'SET_SCHEDULE_DATE', date: scheduleInstance.date });
      setDisplayWaitlistForm(false);
      setWaitlistNote('');
    }
    setError('');
  };

  const setWantServiceSoonerMethod = (value) => {
    setWantServiceSooner(value);
    setDisplayWaitlistForm(!!value);
    setError('');
  };

  const setKeyLocation = (option) => {
    setKeyLocationOption(option);
    setKeyLocationNote(option === 'other' ? '' : KEY_LOCATION_OPTIONS[option]);
    setError('');
  };

  const handleContinue = () => {
    const formIsValid = validateFormFields();
    if (formIsValid) handleSubmit();
  };

  const handleSubmit = () => {
    const hasWorkplaceService = service_locations.includes('workplace');

    setContinueButtonClicked(true);

    if (changeBooking) {
      const { swId } = schedule;

      const payload = {
        new_date: date,
        schedule_instance_id: scheduleInstanceId,
        service_vehicle_id: serviceVehicleId,
        customer_note: customerNote,
        waitlist_note: waitlistNote,
        is_handraise: wantServiceSooner || !date,
        ...(schedulingSystem === 'availability_windows' && {
          planned_arrival_time: plannedArrivalTime,
        }),
        ...(schedulingSystem === 'availability_windows' && {
          planned_departure_time: plannedDepartureTime,
        }),
        ...(schedulingSystem === 'time_slots' &&
          confirmedAppointmentStartTime && {
            confirmed_appointment_start_time: Moment(
              confirmedAppointmentStartTime,
            ).format('HH:mm:00'),
          }),
        token: schedule.token,
        key_exchange_note: keyLocationNote,
      };

      return apiPut(`/booking/scheduled-work/${swId}`, payload).then(() => {
        addMessage(dispatch, 'Your booking was successfully updated!', 10);
        if (goBack) {
          browserHistory.goBack();
        } else {
          browserHistory.push('/account');
        }
      });
    }

    const sched = {
      customerNote,
      date,
      serviceVehicleId,
      scheduleInstanceId,
      waitlist_note: waitlistNote,
      is_handraise: wantServiceSooner || !date,
      isPickupAndDelivery,
      ...(schedulingSystem === 'availability_windows' && {
        plannedArrivalTime,
      }),
      ...(schedulingSystem === 'availability_windows' && {
        plannedDepartureTime,
      }),
      ...(schedulingSystem === 'time_slots' &&
        confirmedAppointmentStartTime && {
          confirmedAppointmentStartTime: Moment(
            confirmedAppointmentStartTime,
          ).format('HH:mm:00'),
        }),
    };

    tracRudderStackkEvent('Service_Scheduled', {
      proname: z3pConfiguration.z3p_client_name,
      first_name: account.first_name,
      last_name: account.last_name,
      email: account.email,
      mobile: account.phone,
      Service_Date: sched.date,
      Service_Time: `${sched.plannedArrivalTime} to ${sched.plannedDepartureTime}`
    });

    dispatch({ type: 'SET_SCHEDULE', schedule: sched });

    if (isAtHomeService) {
      dispatch({
        type: 'SET_HOME_ADDRESS',
        ...homeAddress,
        keyLocationNote,
      });
      return browserHistory.push('/pricing/review');
    }

    if (hasWorkplaceService) {
      return browserHistory.push('/pricing/location');
    }
    return browserHistory.push('/pricing/review');
  };

  const handleTextAgreementCheck = () => {
    setAgreedToReceiveTexts((prev) => !prev);
  };

  const validateFormFields = () => {
    if (!setError) return true;

    const t0 = plannedArrivalTime ? timeStringToHours(plannedArrivalTime) : 9;
    const t1 = plannedDepartureTime
      ? timeStringToHours(plannedDepartureTime)
      : 17;

    // First, check if scheduling time range and minimum onsite hours are configured properly
    if (
      date &&
      defaultMinimumOnsiteHours &&
      timeRangeMax &&
      timeRangeMin &&
      defaultMinimumOnsiteHours > timeRangeMax - timeRangeMin
    ) {
      setError(
        `Your vehicle must be available for a minimum of ${defaultMinimumOnsiteHours} hours.`,
      );
      return false;
    }

    if (!date && displayWaitlistForm && !waitlistNote) {
      setError(
        'Please add your schedule availability before continuing without a date.',
      );
      return false;
    }

    if (!date && !waitlistNote) {
      setError('Please select a date before you can continue.');
      return false;
    }

    if (wantServiceSooner && !waitlistNote) {
      setError(
        'Please add your availability and reason for wanting service sooner.',
      );
      return false;
    }

    if (t0 >= t1) {
      setError('Your leave time must be after your arrive time.');
      return false;
    }

    if (
      date &&
      defaultMinimumOnsiteHours &&
      t1 - t0 < defaultMinimumOnsiteHours
    ) {
      setError(
        `Your vehicle must be available for a minimum of ${defaultMinimumOnsiteHours} hours.`,
      );
      return false;
    }

    if (schedulingSystem === 'time_slots' && !confirmedAppointmentStartTime) {
      setError('Please select a date and time.');
      return false;
    }

    if (isAtHomeService && vehicle?.vehicle_id && !keyLocationNote) {
      setError("Please let us know where your vehicle's keys will be.");
      return false;
    }

    if (z3pEnv === 'aaa-norcal' && customerNote === '') {
      setError(
        'Please enter a note with a description of the issue you are experiencing.',
      );
      return false;
    }

    setError('');
    return true;
  };

  useEffect(() => {
    validateFormFields();
  }, [
    plannedArrivalTime,
    plannedDepartureTime,
    date,
    defaultMinimumOnsiteHours,
    timeRangeMax,
    timeRangeMin,
    displayWaitlistForm,
    waitlistNote,
    wantServiceSooner,
    schedulingSystem,
    confirmedAppointmentStartTime,
    isAtHomeService,
    is_skipping_vehicle,
    keyLocationNote,
    z3pEnv,
    customerNote,
  ]);

  const noteChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length < 1020) {
      setCustomerNote(newValue);
    } else {
      setCustomerNote(newValue.substring(0, 1020));
    }
  };

  const waitlistNoteChange = (event) => {
    const newValue = event.target.value;
    setWaitlistNote(
      newValue.length < 1020 ? newValue : newValue.substring(0, 1020),
    );
    setWaitlistNoteEdited(true);
    setError('');
  };

  const clientLocation = clientLocations.find(
    (cl) => cl.client_location_id === customerLocation?.clientLocationId,
  );
  const tz =
    clientLocation?.time_zone ||
    homeAddress?.address?.time_zone ||
    'America/New_York';

  const setStartTime = (startTimeMoment) => {
    setConfirmedAppointmentStartTime(startTimeMoment.tz(tz));
    setError('');
  };

  const timeMidPoint = Math.round((timeRangeMin + timeRangeMax) / 2);
  const timeRangeMarks = {
    [timeRangeMin]: hoursToTimeString(timeRangeMin),
    [timeMidPoint]: hoursToTimeString(timeMidPoint),
    [timeRangeMax]: hoursToTimeString(timeRangeMax),
  };

  const h1Text = changeBooking
    ? 'Reschedule Your Service'
    : 'Schedule Your Service';
  const h2Text = 'Choose a service date';

  let timeQuestionText = 'When are you available?';
  if (isPickupAndDelivery) {
    timeQuestionText =
      "We'll need to valet your car back to the shop for this service. When will your car be available for pickup?";
  }

  let customerNoteHeader = '';
  if (z3pEnv === 'aaa-norcal') {
    customerNoteHeader =
      'Give us a brief description of the issue you are experiencing with your vehicle (required)';
  } else {
    customerNoteHeader =
      'Anything else we need to know about your service, schedule, or location? (optional)';
  }

  let dateSelector;
  if (schedulingSystem === 'availability_windows') {
    dateSelector = (
      <CompatibleDateSelector
        // This key enables rerendering when date is autoselected
        key={calendarKey}
        // Will only have a value for existing SW that is being rescheduled
        selectedDate={date}
        selectScheduleInstance={selectScheduleInstance}
        useHomeAddress={isAtHomeService}
        setWantServiceSooner={setWantServiceSoonerMethod}
        wantServiceSooner={wantServiceSooner}
        displayWaitlistForm={displayWaitlistForm}
        setAreCompatibleDatesLoading={setAreCompatibleDatesLoading}
        areCompatibleDatesLoading={areCompatibleDatesLoading}
      />
    );
  } else if (is_using_time_blocks) {
    dateSelector = (
      <TimeSlotCalendar
        selectedDate={date}
        setStartTime={setStartTime}
        selectScheduleInstance={selectScheduleInstance}
        startTime={confirmedAppointmentStartTime}
        useHomeAddress={isAtHomeService}
        tz={tz}
      />
    );
  } else {
    dateSelector = (
      <CompatibleDateTimeSelector
        selectedDate={date}
        selectScheduleInstance={selectScheduleInstance}
        useHomeAddress={isAtHomeService}
        displayWaitlistForm={displayWaitlistForm}
        startTime={confirmedAppointmentStartTime}
        setStartTime={setStartTime}
        setAreCompatibleDatesLoading={setAreCompatibleDatesLoading}
        areCompatibleDatesLoading={areCompatibleDatesLoading}
        tz={tz}
      />
    );
  }

  const enableContinue =
    ((date && !wantServiceSooner) || waitlistNote) &&
    !error &&
    !continueButtonClicked &&
    (!isAtHomeService || !!keyLocationNote || !vehicle?.vehicle_id) &&
    agreedToReceiveTexts;

  return (
    <Pricing
      hideBreadcrumbs={changeBooking}
      currentStep="Schedule"
      h1={h1Text}
      h2={h2Text}
      showContinue
      enableContinue={enableContinue}
      onContinue={handleContinue}
      backText="Back To Services"
    >
      <div className="row">
        <div
          className={
            schedulingSystem === 'availability_windows'
              ? 'col s12 m7 l6 calendar'
              : 'col s12 m12 l8 calendar'
          }
        >
          {dateSelector}
        </div>
        <div
          className={
            schedulingSystem === 'availability_windows'
              ? 'col s12 m5 l6 when input-box-responsive'
              : 'col s12 m12 l4 when input-box-responsive'
          }
        >
          {(!!timeRangeMin || timeRangeMin === 0) &&
            (!!timeRangeMax || timeRangeMax === 0) &&
            schedulingSystem === 'availability_windows' && (
              <>
                <div className="row">
                  <h5>{timeQuestionText}</h5>
                  <div className="col s12 center">
                    <Range
                      min={timeRangeMin}
                      max={timeRangeMax}
                      step={0.25}
                      pushable={defaultMinimumOnsiteHours}
                      value={[
                        timeStringToHours(plannedArrivalTime),
                        timeStringToHours(plannedDepartureTime),
                      ]}
                      onChange={setRange}
                      tipFormatter={hoursToTimeString}
                      marks={timeRangeMarks}
                      className='marks-range'
                      tipProps={{ visible: tooltipVisible }}
                    />
                  </div>
                </div>
                <br />
                <div
                  className="row"
                  style={{ alignItems: 'center', flexDirection: 'column' }}
                >
                  <div className="col s12 center slider-info-container">
                    <div
                      className="slider-info-box"
                      onClick={showTooltip}
                      onKeyDown={showTooltip}
                      role="button"
                      tabIndex="0"
                    >
                      {plannedArrivalTime}
                    </div>
                    <div style={{ margin: '0 1em', color: 'grey' }}>to</div>
                    <div
                      className="slider-info-box"
                      onClick={showTooltip}
                      onKeyDown={showTooltip}
                      role="button"
                      tabIndex="0"
                    >
                      {plannedDepartureTime}
                    </div>
                  </div>
                </div>
              </>
            )}

          {!hasShopService &&
              !!messageOnScheduleScreen && (
                  <p>{messageOnScheduleScreen}</p>
              )}

          {displayWaitlistForm && (
            <div className="row">
              <h5>Tell us about your availability</h5>
              <p>
                Since you're booking without a date, please tell us about your
                schedule so that we can better serve you. Preferred dates, days
                of the week, and days that do not work are all helpful.
              </p>
              <textarea
                rows="7"
                className={waitlistNoteEdited ? '' : 'waitlist-note'}
                id="waitlist"
                value={waitlistNote}
                placeholder="Required"
                maxLength="1020"
                onChange={waitlistNoteChange}
              />
              <br />
              <br />
            </div>
          )}

          {schedulingSystem === 'time_slots' &&
            !!confirmedAppointmentStartTime && (
              <h5 style={{ whiteSpace: 'pre' }}>
                {Moment(confirmedAppointmentStartTime).format(
                  'h:mm a[\n]dddd, MMMM Do',
                )}
              </h5>
            )}

          {is_using_time_blocks && confirmedAppointmentStartTime && (
            <span>{`Your selected services should take about ${laborHoursToDisplay}`}</span>
          )}

          {isAtHomeService && vehicle?.vehicle_id ? (
            <div className="row">
              <h5 className="marginBottom0" style={{marginTop:'40px'}}>Key Location</h5>
              <Select
                s={12}
                m={12}
                onChange={(e) => setKeyLocation(e.target.value)}
                id="keyLocationOption"
                name="keyLocationOption"
                value={keyLocationOption}
                required
              >
                <option value="" disabled>
                  Where will we find your keys? *
                </option>
                {Object.entries(KEY_LOCATION_OPTIONS).map(
                  ([optionName, text]) => (
                    <option key={optionName} value={optionName}>
                      {text}
                    </option>
                  ),
                )}
              </Select>
              {keyLocationOption === 'other' && (
                <textarea
                  rows="7"
                  id="note"
                  value={keyLocationNote}
                  placeholder="Provide more detail about key location (Required)"
                  maxLength="1020"
                  onChange={(e) => setKeyLocationNote(e.target.value)}
                />
              )}
            </div>
          ) : null}

          <div className="row text_message_agreement">
            <Checkbox
              id="text_message_agreement"
              onChange={(e) => handleTextAgreementCheck(e)}
              checked={agreedToReceiveTexts}
              className="filled-in"
              label="I agree to receive text messages for important updates and notifications related to my appointment."
            />
          </div>

          <div className="row">
            {!diagnostic.selected && (
              <div>
                <textarea
                  rows="7"
                  id="note"
                  value={customerNote}
                  placeholder={customerNoteHeader}
                  maxLength="1020"
                  onChange={noteChange}
                />
              </div>
            )}

            {changeBooking && (
              <Button
                style={{ marginRight: '1em' }}
                large
                className="grey btn black-text lighten-3"
                onClick={() => browserHistory.push('/account')}
              >
                Go Back
              </Button>
            )}

            <Button className="btn btn-large
            " onClick={handleContinue} disabled={!enableContinue}>
              {continueButtonClicked ? 'Loading...' : 'Continue'}
            </Button>

            <br />
            {error && (
              <span className="neue error">{error}</span>
            )}
          </div>
        </div>
      </div>
    </Pricing>
  );
};

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    schedule: state.ui.schedule,
    cart: state.ui.cart,
    diagnostic: state.ui.diagnostic,
    changeBooking: state.ui.changeBooking,
    homeAddress: state.ui.homeAddress,
    customerLocation: state.ui.customerLocation,
    clientLocations: state.ui.clientLocations,
    compatibleDates: state.ui.compatibleDates,
    z3pConfiguration: state.ui.z3pConfiguration,
    keyLocation: state.ui.keyLocation,
    goBack: state.ui.goBack,
    vehicle: state.ui.pricing,
  };
}

export default connect(mapStateToProps, null)(Schedule);

/* globals alert analytics window fbq dataLayer */

import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Row } from 'react-materialize';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import Moment from 'moment';
import { browserHistory } from 'react-router';
import { apiPost } from '../../../brainApi';
import {
  createTireNote,
  getActivePaymentMethods,
  skipPaymentInformation,
} from '../checkoutHelper';
import CancellationPolicyCheckbox from './CancellationPolicyCheckbox';
import CancellationPolicyModal from './CancellationPolicyModal';
import Select from '../../components/Select';
import { calculateCosts } from '../../../costCalculator';
import SquareModal from '../../components/SquareModal';
import {
  shouldUseFacebookPixel,
  tracRudderStackkEvent,
  currentPageInfo,
} from '../../../helper';
import { getStripePublishableKey } from '../../../paymentProcessor';
import ErrorModal from '../../components/ErrorModal';

const Checkout = (props) => {
  const {
    account,
    activePaymentMethods,
    cart,
    coupon,
    couponCode,
    customDiscount,
    customerLocation,
    dispatch,
    detailingNote,
    homeAddress,
    isAtHomeService,
    isFleetUser,
    keyosk,
    parking,
    pricing,
    processing,
    referrer,
    schedule,
    selectedPaymentMethod,
    isWorkplaceService,
    setCheckTermsAlert,
    setProcessing,
    taxRate,
    tireDetails,
    tireNotes,
    z3pConfiguration,
    areAdditionalContactsValid,
    allowAdditionalContacts,
    additionalContactList,
    tipAmountDollars,
    setTipAmountDollars,
    quoteId,
  } = props;
  const {
    customer_facing_name: serviceProviderName,
    z3p_client_name: z3pEnv,
    is_using_stripe: isUsingStripe,
    is_using_square: isUsingSquare,
    terms_and_conditions_url,
    is_charging_cancellation_fee: hasCancellationFeePolicy,
  } = z3pConfiguration;

  const [checked, setChecked] = useState(false);
  const [
    cancellationPolicyIsChecked,
    setCancellationPolicyIsChecked,
  ] = useState(false);
  const [
    cancellationPolicyModalOpen,
    setCancellationPolicyModalOpen,
  ] = useState(false);
  const [showPaymentMethodSection, setShowPaymentMethodSection] = useState(
    false,
  );
  const [isFree, setIsFree] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [timeTakenError, setTimeTakenError] = useState(false);
  const [
    shouldDisableScheduleButton,
    setShouldDisableScheduleButton,
  ] = useState(false);

  useEffect(() => {
    getActivePaymentMethods(dispatch, isUsingStripe, isUsingSquare);
  }, []);

  useEffect(() => {
    const { finalTaxedTotalDollars } = calculateCosts(
      coupon,
      cart,
      account.default_client_location_id,
      taxRate,
      Moment(),
      0,
      customDiscount,
    );
    const serviceIsFree = finalTaxedTotalDollars === 0;
    setIsFree(serviceIsFree);

    // Do not ask for payment information
    if (
      serviceIsFree ||
      isFleetUser ||
      skipPaymentInformation(activePaymentMethods, isUsingStripe)
    ) {
      setShowPaymentMethodSection(false);
    } else if (activePaymentMethods.length > 1) {
      setShowPaymentMethodSection(true);
    }
  }, [activePaymentMethods, coupon]);

  const submitScheduledWork = (token) => {
    // Send the validated coupon if present.
    // Else send the string that's currently in the "coupon input" text box.
    let couponUpper = '';
    if (coupon && coupon.coupon_code) {
      couponUpper = coupon.coupon_code.toUpperCase();
    } else {
      couponUpper = couponCode.toUpperCase();
    }

    // Concat all the notes into one.
    // In the future, we will make it so Brain2 accepts multiple separate notes.
    tireDetails.tireNote = createTireNote(tireNotes);
    tireDetails.tireNotes = tireNotes;

    const additionalPhoneNumbers = additionalContactList.map(
      (contact) => contact.phoneNumber?.length === 10 && contact.phoneNumber,
    );

    const updatedCart = [];
    // If a service has quantity more than 1, create that number of services
    cart.forEach((service) => {
      const quantity = service.quantity || 1;
      if (quantity > 1) {
        for (let i = 0; i < quantity; i += 1) {
          updatedCart.push(service);
        }
      } else {
        updatedCart.push(service);
      }
    });

    const payload = {
      pricing,
      account,
      client_location_id: isAtHomeService
        ? null
        : customerLocation.clientLocationId,
      vehicle_id: pricing?.vehicle_id || null,
      keyosk_id: keyosk && keyosk.keyosk_id,
      parking_id: parking && parking.parking_id,
      customer_address_id: homeAddress.addressIDSelected,
      key_exchange_note: homeAddress.keyLocationNote,
      schedule,
      services: updatedCart,
      coupon_code: couponUpper,
      referrer_code: referrer.code,
      tireDetails,
      detailingNote,
      payment_method_id:
        selectedPaymentMethod && !isFree
          ? selectedPaymentMethod.payment_method_id
          : null,
      additional_phone_numbers: additionalPhoneNumbers,
      tip_amount_dollars: tipAmountDollars,
      tip_entry_method: tipAmountDollars > 0 ? 'checkout' : null,
      quote_id: quoteId,
    };

    if (customDiscount) {
      payload.custom_discount_amount_dollars =
        customDiscount.customDiscountAmountDollars;
      payload.custom_discount_reason = customDiscount.customDiscountReason;
    }

    if (isUsingStripe && token) {
      payload.stripe_token = token.id;
    } else if (isUsingSquare && token) {
      payload.square_token = token;
    }

    setPaymentFailed(false); // in case of retries
    setTimeTakenError(false);
    setProcessing(true);
    apiPost('/booking/scheduled-work', payload).then((response) => {
      if (response.status !== 200) {
        response.text().then((x) => {
          alert(`Critical Error: ${x}`);
        });
        setProcessing(false);
        return;
      }

      response.json().then((json) => {
        if (json.Status === 'Failure' && json.Message === 'Invalid card') {
          setPaymentFailed(true);
          setProcessing(false);
          return;
        }
        if (
          json.Status === 'Failure' &&
          json.Message === 'Double booking attempted'
        ) {
          setTimeTakenError(true);
          setProcessing(false);
          return;
        }

        // TODO: This revenue calculation isn't exactly correct
        const revenue = payload.services.reduce(
          (sum, service) => sum + service.total_cost_dollars,
          0,
        );

        const products = payload.services.map((service) => ({
          sku: service.service_offering_id,
          name: service.long_name,
          price: service.total_cost_dollars,
          parts_cost_dollars: service.parts_cost_dollars,
          labor_cost_dollars: service.labor_cost_dollars,
        }));

        const serviceDate = Moment(
          payload.schedule.date,
          'YYYY-MM-DD',
        ).toISOString();

        const userAnalyticsAttributes = {
          last_scheduled_service_date: serviceDate,
          vehicle_make: payload.pricing?.make,
          vehicle_model: payload.pricing?.model,
          vehicle_year: payload.pricing?.year,
        };

        const analyticsEvent = {
          event: 'Order Completed',
          event_category: 'Order',
          event_label: 'Order Completed',
          revenue,
          products: JSON.stringify(products),
          serviceDate,
          arriveTime: Moment(
            `${payload.schedule.date} ${payload.schedule.arrive}`,
            'YYYY-MM-DD h:mm A',
          ).toISOString(),
          leaveTime: Moment(
            `${payload.schedule.date} ${payload.schedule.leave}`,
            'YYYY-MM-DD h:mm A',
          ).toISOString(),
          service_vehicle_id: payload.schedule.serviceVehicleId,
          note: payload.schedule.note,
          vehicle_make: payload.pricing?.make,
          vehicle_model: payload.pricing?.model,
          vehicle_year: payload.pricing?.year,
          coupon: payload.coupon,
          location: payload.account.location,
          keyosk: payload.keyosk,
          parking: payload.parking,
        };

        tracRudderStackkEvent('Service_Confirmation', {
          proname: z3pConfiguration.z3p_client_name,
          path: window.location.pathname,
          url: window.location.href,
          title: document.title,
          referrer: document.referrer,
          service_date: payload.schedule.date,
          service_time: `${payload.schedule.plannedArrivalTime} to ${payload.schedule.plannedDepartureTime}`,
          Payment_Method: selectedPaymentMethod?.name,
        });

        // Push to dataLayer to be picked up by GTM
        if (typeof dataLayer !== 'undefined') {
          dataLayer.push(analyticsEvent);
        }

        // Add gtag event to be picked up by Google Analytics
        if (window && window.gtag && window.location) {
          window.gtag('event', 'Order Completed', analyticsEvent);
        }

        if (shouldUseFacebookPixel(z3pEnv)) {
          // Tell Facebook Pixel about this purchase
          fbq('track', 'Purchase', {
            currency: 'USD',
            value: revenue,
            content_ids: cart.map((service) => service.service_offering_id),
          });
        }

        /* dispatch({ type: 'SET_CART', cart: [] }); */

        dispatch({
          type: 'CLEAR_TIRE_DETAILS',
        });

        dispatch({
          type: 'SET_DETAILING_NOTE',
          detailingNote: '',
        });

        dispatch({
          type: 'SET_COUPON',
          coupon: null,
        });

        dispatch({
          type: 'SET_CUSTOM_DISCOUNT',
          customDiscount: null,
        });

        dispatch({
          type: 'CLEAR_MESSAGES',
        });

        dispatch({
          type: 'SET_SCHEDULED_WORKS',
          scheduledWorks: json.scheduledWorks,
        });

        dispatch({ type: 'SET_SCHEDULE_DATE', date: '' });

        dispatch({
          type: 'SET_SELECTED_PAYMENT_METHOD',
          selectedPaymentMethod: null,
        });

        dispatch({
          type: 'SET_QUOTE_ID',
          quoteId: null,
        });

        if (z3pEnv === 'bigs-mobile-detailing') {
          window.location = 'https://bigsmobile.com/thank-you-zippity/';
        } else {
          browserHistory.push('/pricing/finished');
        }
      });
    });
  };

  const shouldDisable = () => {
    const checkForPaymentStatus =
      (selectedPaymentMethod && selectedPaymentMethod.is_square) ||
      (!selectedPaymentMethod && isUsingSquare);
    let disable;
    if (checkForPaymentStatus) {
      disable =
        (hasCancellationFeePolicy && !cancellationPolicyIsChecked) ||
        (terms_and_conditions_url && !checked) ||
        (!paymentFailed && processing) ||
        (allowAdditionalContacts && !areAdditionalContactsValid);
    } else {
      disable =
        (hasCancellationFeePolicy && !cancellationPolicyIsChecked) ||
        (terms_and_conditions_url && !checked) ||
        processing ||
        (allowAdditionalContacts && !areAdditionalContactsValid);
    }
    setShouldDisableScheduleButton(disable);
  };

  useEffect(() => {
    shouldDisable();
  }, [
    cancellationPolicyIsChecked,
    checked,
    paymentFailed,
    processing,
    allowAdditionalContacts,
    areAdditionalContactsValid,
    selectedPaymentMethod,
  ]);

  // This is the non-Stripe onClick handler
  const handleCheckoutClick = () => {
    if (terms_and_conditions_url && !checked) {
      setCheckTermsAlert(true);
    }
    if (processing) {
      return null;
    }

    return submitScheduledWork({ id: undefined });
  };
  const checkoutButton = (text, style) => (
    <Button
      disabled={shouldDisableScheduleButton}
      onClick={handleCheckoutClick}
      large
      className={`btn header ${processing && 'grey lighten-1'}`}
      style={style}
    >
      {text}
    </Button>
  );

  const stripeCheckout = (text, style) => {
    const stripeKey = getStripePublishableKey();
    return (
      <StripeCheckout
        disabled={shouldDisableScheduleButton}
        email={account.email}
        token={submitScheduledWork}
        stripeKey={stripeKey}
        allowRememberMe={false}
        name={serviceProviderName}
        description="Card charged on service day"
        panelLabel="Authorize"
        zipCode
      >
        <Button
          disabled={shouldDisableScheduleButton}
          large
          className={`header ${processing && 'grey lighten-1'}`}
          style={style}
          onClick={() => {
            if (terms_and_conditions_url && !checked) {
              setCheckTermsAlert(true);
            }
          }}
        >
          {text}
        </Button>
      </StripeCheckout>
    );
  };

  const squareCheckout = (text) => (
    <SquareModal
      disabled={shouldDisableScheduleButton}
      paymentFailed={paymentFailed}
      onToken={(token) => submitScheduledWork(token)}
      openButtonText={text}
      submitButtonText="Authorize"
    />
  );

  const paymentMethodSection = (
    <div className="payment_method_box">
      <h5 style={{ marginBottom: '-10px' }}>Payment Method</h5>
      <Row style={{ marginBottom: '0px', paddingLeft: '5px' }}>
        <Select
          id="payment-method"
          s={12}
          l={6}
          value={
            selectedPaymentMethod && selectedPaymentMethod.payment_method_id
          }
          onChange={(e) => {
            const selectedMethod = activePaymentMethods.find(
              (method) => method.payment_method_id === Number(e.target.value),
            );
            dispatch({
              type: 'SET_SELECTED_PAYMENT_METHOD',
              selectedPaymentMethod: selectedMethod,
            });
            if (!selectedMethod.is_square) {
              setTipAmountDollars(0);
            }
          }}
        >
          {activePaymentMethods.map((method) => (
            <option
              key={method.payment_method_id}
              value={method.payment_method_id}
            >
              {method.short_description}
            </option>
          ))}
        </Select>
      </Row>
      <div style={{ paddingLeft: '5px' }}>
        {selectedPaymentMethod && selectedPaymentMethod.long_description}
      </div>
      <br />
    </div>
  );

  const handleCheck = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setChecked(value);
  };

  const employerText = isWorkplaceService && (
    <div className="small">
      I understand that this service is not provided by my employer and they are
      not responsible for the satisfaction or result of any services.
    </div>
  );

  const termsLabel = () => {
    return (
      <label htmlFor="test5" style={{ fontSize: '1rem' }}>
        I agree to the{' '}
        <a href={terms_and_conditions_url} target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>{' '}
        of {serviceProviderName}.{employerText}
      </label>
    );
  };

  if (isFleetUser && isUsingStripe) {
    return (
      <div>
        {terms_and_conditions_url && (
          <Checkbox
            id="test5"
            onChange={handleCheck}
            value={checked}
            label={termsLabel()}
          />
        )}

        <span style={{ marginRight: '10px' }}>
          {checkoutButton(
            <span>
              Schedule Now
              <br />
              Pay With Fleet Account
            </span>,
            { fontSize: '1.3rem', lineHeight: '27px' },
          )}
        </span>

        <span>
          {stripeCheckout(
            <span>
              Schedule Now
              <br />
              Pay With Credit Card
            </span>,
            { fontSize: '1.3rem', lineHeight: '27px' },
          )}
        </span>
      </div>
    );
  }
  const buttonText = processing ? 'Scheduling...' : 'Schedule Your Service';
  let button;
  if (isFree) {
    button = checkoutButton(buttonText, null);
  } else if (selectedPaymentMethod && selectedPaymentMethod.is_stripe) {
    button = stripeCheckout(buttonText, null);
  } else if (!selectedPaymentMethod && isUsingStripe) {
    button = stripeCheckout(buttonText, null);
  } else if (selectedPaymentMethod && selectedPaymentMethod.is_square) {
    button = squareCheckout(buttonText);
  } else if (!selectedPaymentMethod && isUsingSquare) {
    button = squareCheckout(buttonText);
  } else {
    button = checkoutButton(buttonText, null);
  }

  return (
    <>
      {showPaymentMethodSection && <div className="section_divider" />}
      {showPaymentMethodSection && paymentMethodSection}

      {terms_and_conditions_url && (
        <Checkbox
          id="test5"
          onChange={handleCheck}
          value={checked}
          label={termsLabel()}
        />
      )}
      {hasCancellationFeePolicy && (
        <CancellationPolicyCheckbox
          cancellationPolicyIsChecked={cancellationPolicyIsChecked}
          setCancellationPolicyIsChecked={setCancellationPolicyIsChecked}
          setCancellationPolicyModalOpen={setCancellationPolicyModalOpen}
        />
      )}
      <CancellationPolicyModal
        open={cancellationPolicyModalOpen}
        onCancel={() => setCancellationPolicyModalOpen(false)}
      />
      <div style={{ marginTop: '40px', padding: '0 18px' }}>{button}</div>
      <ErrorModal
        error={timeTakenError}
        onClose={() => {
          browserHistory.push('/pricing/schedule');
        }}
        errorText="Sorry, the time you have chosen is now unavailable. Please select another time."
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    account: state.ui.account,
    activePaymentMethods: state.ui.activePaymentMethods,
    cart: state.ui.cart,
    coupon: state.ui.coupon,
    customDiscount: state.ui.customDiscount,
    customerLocation: state.ui.customerLocation,
    detailingNote: state.ui.detailingNote,
    homeAddress: state.ui.homeAddress,
    keyosk: state.ui.keyosk,
    parking: state.ui.parking,
    pricing: state.ui.pricing,
    quoteId: state.ui.quoteId,
    referrer: state.ui.referrer,
    schedule: state.ui.schedule,
    selectedPaymentMethod: state.ui.selectedPaymentMethod,
    taxRate: state.ui.taxRate,
    tireDetails: state.ui.tireDetails,
    tireNotes: state.ui.tireNotes,
    z3pConfiguration: state.ui.z3pConfiguration,
  };
}

export default connect(mapStateToProps, null)(Checkout);
